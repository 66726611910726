@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.error-log{
  margin-left: 10px;
  .stacktrace {
    margin-left: 10px;
  }
}
.table-row-hidden {
  display: none;
}
.td-test-expand {
  background-color: #e6f3ff;
  color: black;
}
.test-logs-toggle {
  border: 0;
  background-color: #fafafa;
}
.FAILED-test {
  color: red;
  font-weight: bold;
}
.PASSED-test {
  color: green;
  font-weight: bold;
}
.test-icon {
  color: #2b6bdd;
}

.span-group {
  overflow: auto;
  clear: both;
  font-size: 18px;
}
.span-left {
  cursor: auto;
  width: 28%;
  padding-right: 20px;
  font-weight: bold;
  text-align: right;
  float: left;
  padding-top: 5px;
}
.span-right {
  cursor: auto;
  display: inline-block;
  width: 55%;
  word-break: break-all;
  padding-top: 5px;
}
.underline {
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  background-color:#ccc;
}

//todo width:30% is not a perfect solution.
.expand-left {
  margin-left: 5px;
  width: 100%;
  vertical-align: top;
  display: inline-block;
}

.test-status-reason {
  white-space: pre;
}
