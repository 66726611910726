@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';


.stageResults {
  .stageName {
    cursor: pointer;
  }
  .stageArrow {
    color: grey;
  }
  .stageResult.PASSED {
    color: green;
  }
  .stageResult.FAILED {
    color: red;
  }
  .stageResult.UNKNOWN {
    color: orange;
  }
}

