@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.suiteButton {
  margin: 10px;
  font-size: 18px;
  text-align: left;
}

.suitesNav {
  ul {
    list-style: none;

    padding-left: 0px;
    font-size: 22pt;
    color: #000;

    ul {
      padding-left: 20px;
      font-size: 18pt;
      color: #555;

      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid #aaa;

      ul {
        font-size: 14pt;
        color: #777;
        padding-bottom: 5px;
        margin-bottom: 0px;
        border-bottom: 0px;
        font-family: "Monaco", "Roboto Mono", "SF Mono", Courier, Monospace;

        li {
          display: inline-block;
          padding-top: 5px;
          padding-bottom: 20px;

          a {
            padding: 5px 15px;
            margin: 2px 6px;
            background-color: #fff;
            border: 1px solid #0080ff;
            border-radius: 8px;
            color: #0080ff; 
          }

          a:hover {
            background-color: #f0f7ff;
            border: 1px solid #006edb;
            color: #006edb; 
          }
        }
      }
    }

  }
  li {
    font-weight: bold;
  }
}
