@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

th, td.stageStatus, td.testStatus {
  padding: 8px 0 !important;
  position: relative;

  .statusTooltip {
    visibility: hidden;
    background-color: #ccc;
    border: 1px solid #555;
    padding: 1px 3px;
    border-radius: 6px;
    bottom: -25px;
    right: 0;
   
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;

    white-space: nowrap;

    &::after {
      content: "";
      position: absolute;
      top: -15px;
      right: 3px;
      margin-left: -8px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent #555 transparent;
    }
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  &:hover .statusTooltip {
    visibility: visible;
  }
}

.sticky {
  position: sticky;
  position: -webkit-sticky;    
  left: 0px;  
  background-color: rgb(250, 250, 250);
  border-top: 1px solid #eeeeee;
}

td.testStatus {
  border-left: 4px solid #777 !important;
}

td.stageStatus, td.testStatus {
  text-align: center;

  &.NO_RESULT_SET, &.UNKNOWN_RESULT {
    background-color: rgba(255, 0, 0, .25);

    &::before {
      content: "🚫"
    }
  }

  &.PASSED {
    background-color: rgba(0, 255, 0, .1);

    &::before {
      content: "✅"
    }
  }

  &.FAILED {
    background-color: rgba(255, 0, 0, .25);

    &::before {
      content: "❌"
    }
  }

  &.SKIPPED {
    background-color: rgba(0, 0, 255, .1);

    &::before {
      content: "➡️"
    }
  }

  &.UNKNOWN {
    background-color: rgba(120, 120, 120, .1);

    &::before {
      content: "🔲"
    }
  }
}

tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.05);
  transition: 0.1s;
}
