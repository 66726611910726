@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.timestamp {

    .utc-link {
        //margin: 5px 10px;
        //font-size: 8px;
        //line-height: 10px;
        //color: #2b6bdd;
        cursor: pointer;
    }
}

.utc-popover {
    font-family: "Monaco", "Roboto Mono", "SF Mono", Courier, Monospace;
}
