@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.logTableContainer {
  margin: 5px 20px;
}

.keepNewLines {
  white-space: pre;
}

.log-filters {

  .span-group {
    margin-bottom: 5px;

    .span-left {
      margin-top: 5px;
    }
  }

  .log-toggle {
    margin: 5px;
  }

  .searchableSelectInput {
    margin-left: 28%;
    margin-top: -38px;
    .svgicon {
      top: 6px;
    }
  }

  .textFilter {
    margin-left: 28%;
    margin-top: -38px;
    #log-filter-label {
      display: hidden;
    }
  }
}

@keyframes highlightlog {
  0% {background-color: #fafafa;}
  10% {background-color: #ccffd3;}
  20% {background-color: #f5fcf5;}
  30% {background-color: #bfffc7;}
  40% {background-color: #f5fcf5;}
  50% {background-color: #bfffc7;}
  60% {background-color: #f5fcf5;}
  70% {background-color: #bfffc7;}
  80% {background-color: #f5fcf5;}
  100% {background-color: #ccffd3;}
}

.log-table {

  tbody {

    tr, td {
      white-space: nowrap;
      font-family: "Monaco", "Roboto Mono", "SF Mono", Courier, Monospace;
      font-size: 14px;
      line-height: 14px;
      padding: 6px 12px !important;
      border-color: #FAFAFA;


      &.log-header {

        //background-color: red;

        // default to size 4 and higher
        td {
          //background-color: rgba(37, 191, 230, 0.05);
          border-color: #ccc;
          &.message::before {
            content: "⟼ ";
          }
          &.message {
            font-family: Arial;
            font-weight: 800;
            line-height: 21px;
            font-size: 14px;
            color: #555;
          }
        }

        &.log-header-size-1 td {
          background-color: rgba(37, 191, 230, 0.3);
          border-color: #999;
          &.message::before {
            content: "";
          }
          &.message {
            font-size: 18px;
            color: #000;
          }
        }

        &.log-header-size-2 td {
          background-color: rgba(37, 191, 230, 0.2);
          border-color: #aaa;
          &.message::before {
            content: "⤇ ";
          }
          &.message {
            font-size: 16px;
            color: #222;
          }
        }

        &.log-header-size-3 td {
          //background-color: rgba(37, 191, 230, 0.1);
          border-color: #bbb;
          &.message::before {
            content: "⟾ ";
          }
          &.message {
            font-size: 14px;
            color: #444;
          }
        }

      }
    }

    tr.level-TRACE {
      color: #CACFD9;
    }
    tr.level-DEBUG {
      color: #B2B6BF;
    }
    tr.level-WARN {
      background-color: #FFF5B3;
      &.log-line {
        font-weight: bold;
      }
    }
    tr.level-ERROR {
      background-color: #FFCCCC;
      &.log-line {
        font-weight: bold;
      }
    }

    tr.highlighted {
      background-color: #ccffd3;
      color: #3d6e45;
      animation-name: highlightlog;
      animation-duration: 2s;
    }

    .logNumber {
      user-select: none;
      text-align: right;
    }

    tr td.message {
      white-space: pre;
    }

    .contextToggle {
      font-size: 6px;
      user-select: none;

      &.clickable {
        cursor: pointer;
      }

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .log-details {
      background-color: #F5F6F7;

      table.context {
        width: 100%;
        color: #16171A;
        tr {
          td {
            vertical-align: top;

            &.contextKey {
              white-space: nowrap;
              div {
                padding: 10px;
                font-size: 14px;
                font-weight: bold;
                min-width: 150px;
                text-align: right;
              }
            }

            &.contextValue {
              width: 99%;
              position: relative;
              .linkTip {
                right: 34px;
                top: 20px;
                position: absolute;
              }
            }
          }
        }
      }

      .errorStacktrace {
        width: 99%;
        pre {
          margin: 10px;
          margin-top: 0px;
        }
      }

      pre {
        font-size: 14px;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        background-color: #E4E9EFaa;
        color: #16171A;
      }
    }

    .wrapContext {
      // wrap text, and break words to do it.
      white-space: normal;
      overflow-wrap: break-word;
      word-wrap: break-word;
      -ms-word-break: break-all;
      word-break: break-all;
      word-break: break-word; // fall back for other browsers
      -ms-hyphens: none;
      -moz-hyphens: none;
      -webkit-hyphens: none;
      hyphens: none;
    }
  }
}